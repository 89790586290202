import React, { useContext, useEffect, useRef, useState } from "react";

import { ReactComponent as Logo } from "../../images/logo-digi.svg";

import { diagnosisQuestion } from "../../data/testQuestions";

//Component
import Question from "../Question";
import { UserInfoContext } from "../../contexts/UserInfoContext";

const AutoDiagnosis = ({ screen, goForward }) => {
	const thisElement = useRef(null);
	const [height, setHeight] = useState(568);
	const { userInfo } = useContext(UserInfoContext);

	useEffect(() => {
		setHeight(thisElement.current.clientHeight);

		setTimeout(() => {
			setHeight(thisElement.current.clientHeight);
		}, 100);
	}, [thisElement]);

	return (
		<div
			className="screen auto-diagnosis"
			style={{
				transform: `translateY(calc(${screen * -1} * ${height}px))`,
				zIndex: screen === 0 ? 100 : 0,
			}}
			ref={thisElement}
		>
			<div className="top-container">
				<Logo className="logo" />

				<strong>Observa los siguientes perfiles y elige la opción que mejor se adecue a tu situación actual</strong>

				<div className="container">
					<div className="profiles">
						<div className="card">
							<div className="text">
								<h2>Junior</h2>
								<div className="description">
									<strong>Qué puede hacer:</strong>
									<p>Presentarse a sí mismo.</p>
									<p>Mencionar sus datos personales.</p>
									<p>Comunicarse con frases cortas o palabras aisladas.</p>
									<p>Entender instrucciones muy básicas en inglés.</p>
									<strong>Limitaciones:</strong>
									<p>No puede comunicarse utilizando oraciones completas en inglés.</p>
									<p>Entiende poco en inglés.</p>
								</div>
							</div>

							<span>No tiene fluidez para dialogar, recurre con frecuencia al español.</span>
						</div>
						<div className="card">
							<div className="text">
								<h2>Middle</h2>
								<div className="description">
									<strong>Qué puede hacer:</strong>
									<p>Interactuar en diálogos concretos sobre temas cotidianos.</p>
									<p>Describir su agenda, sus tareas diarias y preferencias.</p>
									<p>Entender cuando le hablan en inglés a un ritmo normal.</p>
									<strong>Limitaciones:</strong>
									<p>Le dificulta comprender detalles por falta de vocabulario.</p>
									<p>Le complica opinar o debatir en temas específicos.</p>
									<p>Conoce el tiempo verbal pasado, pero no puede usarlo.</p>
									<p>Debe mejorar el listening.</p>
								</div>
							</div>

							<span>Fluidez: baja, recurre en algunas ocasiones al español.</span>
						</div>
						<div className="card">
							<div className="text">
								<h2>Senior</h2>
								<div className="description">
									<strong>Qué puede hacer:</strong>
									<p>Interactuar con naturalidad cuando habla de rutinas, trabajo y objetivos.</p>
									<p>Describir experiencias y expresar opiniones.</p>
									<p>Entender fácilmente cuando le hablan en inglés.</p>
									<strong>Limitaciones:</strong>
									<p>Puede usar el pasado, pero ocasionalmente comete errores.</p>
									<p>Tiene problemas de pronunciación.</p>
									<p>Bajo vocabulario laboral.</p>
									<p>Le dificulta opinar sobre temas que requieren vocabulario específico.</p>
								</div>
							</div>

							<span>Fluidez: media, no recurre al español.</span>
						</div>
						<div className="card">
							<div className="text">
								<h2>Expert</h2>

								<div className="description">
									<strong>Qué puede hacer:</strong>
									<p>Usa todos los tiempos verbales.</p>
									<p>Interactúa, argumenta y debate sin inconvenientes.</p>
									<p>Entiende sin ninguna dificultad a hablantes nativos.</p>

									<strong>Limitaciones:</strong>
									<p>No utiliza expresiones idiomáticas del inglés nativo.</p>
									<p>Requiere de vocabulario más específico.</p>
									<p>Comete errores simples por falta de práctica.</p>
									<p>Necesita mejorar su comunicación efectiva.</p>
								</div>
							</div>

							<span>Fluidez: alta.</span>
						</div>
					</div>
					<div className="card">
						<Question data={diagnosisQuestion} goForward={goForward} screen={screen} />					
					</div>
				</div>
			</div>
		</div>
	);
};

export default AutoDiagnosis;

import React, { use, useContext, useEffect, useRef, useState } from "react";

import { ReactComponent as Logo } from "../../images/logo-digi.svg";

//Context
import { UserInfoContext } from "../../contexts/UserInfoContext";

//Helpers
import { emailFormatIsValid } from "../../helpers/utilities";

const Form = ({ screen, setEmailIsAuthorized, pulledInfo }) => {
	const thisElement = useRef(null);
	const [height, setHeight] = useState(568);

	const { setUserInfo } = useContext(UserInfoContext);

	//Input Values
	const [first, setFirst] = useState("");
	const [last, setLast] = useState("");
	const [email, setEmail] = useState("");
	const [plan, setPlan] = useState("");

	useEffect(() => {
		if (pulledInfo !== null) {
			setFirst(pulledInfo.name);
			setLast(pulledInfo.lastname);
			setEmail(pulledInfo.email);
			setPlan(pulledInfo.plan);
		}
	}, [pulledInfo]);

	//Form
	const [error, setError] = useState({});
	const [emailError, setEmailError] = useState(null);

	//To calculate the size of each screen
	useEffect(() => {
		setHeight(thisElement.current.clientHeight);

		setTimeout(() => {
			setHeight(thisElement.current.clientHeight);
		}, 100);
	}, [thisElement]);

	//Handle email error
	const emailIsValid = () => {
		if (email !== "") {
			if (emailFormatIsValid(email)) {
				return true;
			} else {
				//Email invalid
				setEmailError(2);
			}
		} else {
			//Email empty
			setEmailError(1);
		}

		return false;
	};

	//Clean error messages
	useEffect(() => {
		if (email !== "") setEmailError(0);
	}, [email]);
	useEffect(() => {
		if (first !== "") setError({ ...error, first: false });
		// eslint-disable-next-line
	}, [first]);
	useEffect(() => {
		if (last !== "") setError({ ...error, last: false });
		// eslint-disable-next-line
	}, [last]);

	//Detect if text fields are empty
	const textFieldsAreValid = () => {
		let firstError = false;
		let lastError = false;

		if (first === "") {
			firstError = true;
		}
		if (last === "") {
			lastError = true;
		}

		setError({ name: firstError, lastName: lastError });

		if (!firstError && !lastError) {
			return true;
		} else {
			return false;
		}
	};

	//Check everyting
	const checkInputs = () => {
		if (emailIsValid() && textFieldsAreValid()) {
			setUserInfo({ email, first, last, plan });

			setEmailIsAuthorized(true);
		}
	};

	useEffect(() => {
		checkInputs();
	}, [email, first, last]);

	return (
		<div
			className="screen form"
			style={{
				transform: `translateY(calc(${(screen - 1) * -1} * ${height}px))`,
				zIndex: screen === 1 ? 100 : 0,
			}}
			ref={thisElement}
		>
			<div className="top-container">
				<Logo className="logo" />

				<strong>Primero las preguntas faciles</strong>

				<div className="container">
					<label>
						<p>
							Email
							<span className="asterisk">*</span>
							<span className="error">{emailError === 1 ? "Required" : emailError === 2 ? "Email is invalid" : ""}</span>
						</p>
						<input type="email" name="email" tabIndex={screen === 0 ? -1 : 1} placeholder="example@example.com" autoComplete="on" value={email} />
					</label>
					<label>
						<p>
							Name
							<span className="asterisk">*</span>
							{error.name && <span className="error">Required</span>}
						</p>
						<input type="text" name="name" tabIndex={screen === 0 ? -1 : 2} placeholder="John" autoComplete="on" value={first} onChange={(e) => setFirst(e.target.value)} />
					</label>
					<label>
						<p>
							Last Name
							<span className="asterisk">*</span>
							{error.lastName && <span className="error">Required</span>}
						</p>
						<input type="text" name="family-name" tabIndex={screen === 0 ? -1 : 3} placeholder="Smith" autoComplete="on" value={last} onChange={(e) => setLast(e.target.value)} />
					</label>

					<button tabIndex={screen === 0 ? -1 : 4} className="button blue" onClick={checkInputs}>
						Continuar
					</button>
				</div>
			</div>
		</div>
	);
};

export default Form;

export const emailFormatIsValid = (email) => {
  const exp = new RegExp(
    /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  return exp.test(email);
};

export const groupDataBasedOnPosition = (array, position) => {
  let newArray = [];
  let counter = 0;
  let size = 3;

  for (let i = position; i < array.length; i++) {
    if (counter < size) {
      counter++;
      newArray.push(array[i]);
    } else {
      break;
    }
  }

  return newArray;
};

export const calculateScore = (data, diagnosis, choices) => {
  let finalScore = 0;
  let array = [...data];

  array.pop();

  for (let i = 1; i < array.length; i++) {
    if (array[i].answer === choices[i].choice) {
      finalScore += array[i].points;
    }
  }

  if (choices[0].choice !== null) {
    finalScore += diagnosis.options.find(
      (option) => option.id === choices[0].choice
    ).points;
  }

  return finalScore;
};

export const generateResultStructure = (length) => {
  let array = [];

  for (let i = 0; i < length - 1; i++) {
    array.push({ id: i, choice: null });
  }

  return array;
};

import React, { useEffect, useState } from "react";

import TopComponent from "./TopComponent";
import Instructions from "./Instructions";

const screenLimit = 2;

const Scheduler = ({ userHasBestPlan, date, setDate, saveData, uploadStatus, score, setUploadStatus, getScore }) => {
	const [screen, setScreen] = useState(0);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const goForward = () => {
		if (screen < screenLimit) {
			if (screen === 1) {
				setLoading(true);
			} else {
				setScreen(screen + 1);
			}
		} else {
			alert("You have reached the end");
		}
	};

	const goPrevious = () => {
		setScreen(1);
		setUploadStatus(0);
	};

	useEffect(() => {
		if (uploadStatus === 1) {
			setLoading(false);

			if (screen === 1) {
				setScreen(screen + 1);
			}
		} else if (uploadStatus === 2) {
			setLoading(false);
			setError(true);
		}
	}, [uploadStatus]);

	useEffect(() => {
		getScore();
	}, []);

	return (
		<TopComponent>
			<Instructions screen={screen} goForward={goForward} index={2} />
		</TopComponent>
	);
};

export default Scheduler;
